import React from 'react'
import { graphql } from 'gatsby';
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from 'gatsby';
import { Helmet } from "react-helmet";

import AddToCalendar from 'react-add-to-calendar';

import { device } from '../styles/Breakpoints.js';

import styled from 'styled-components';



const backgroundColor = (props) => {
  if (props.pageData.enable_bg_gradient && props.pageData.bg_gradient_one) {
    return `
      -webkit-linear-gradient(${props.pageData.bg_gradient_one.hex}, ${props.pageData.bg_gradient_two.hex}, ${props.pageData.bg_gradient_three.hex});
      `
  }
  else if (props.pageData.bg_color) {
    return `
      rgba(${props.pageData.bg_color.rgb.r}, 
        ${props.pageData.bg_color.rgb.g},
        ${props.pageData.bg_color.rgb.b},
        ${props.pageData.bg_color.rgb.a})
      ` 
  }
  else {
    return '#5d5d5d'
  }
}



const LinktreeStyles = styled.div`
        min-height: 100vh;
        width: 100vw;
        position: relative;
        background: ${props => backgroundColor(props)};
        /* background-color: rgba( ${props => props.pageData.background_color.rgb.r}, 
                                ${props => props.pageData.background_color.rgb.g},
                                ${props => props.pageData.background_color.rgb.b},
                                ${props => props.pageData.background_color.rgb.a}
                            ); */
        .background-image {
            position: absolute;
            top: 0;
            left: 0;
            height: 100vh;
            width: 100vw;
            display: none;
            @media ${device.$medium} {
                display: block;
            }
            & > div {
                height: 100%;
            }
        }
        .logo-wrapper {
          .logotype {
            padding: 48px 0;
            font-size: 48px;
            font-weight: 700;
            color: rgba( ${props => props.pageData.button_color.rgb.r}, 
                                ${props => props.pageData.button_color.rgb.g},
                                ${props => props.pageData.button_color.rgb.b},
                                ${props => props.pageData.button_color.rgb.a} );
          }
        }
        .linktree-wrapper {
            position: relative;
            height: 100%;
            width: 100%;
            z-index: 2;
        }
        .linktree-container {
            text-align: center;
            width: 100%;
            max-width: 414px;
            padding: 24px 12px 8px;
            margin: 0 auto;
            .logo-wrapper {
              margin-bottom: 32px;
            }
            .button {
                font-weight: 700;
                width: 100%;
                margin-bottom: 16px;
                padding: 16px;
                display: flex;
                justify-content: center;
                align-items: center;
                border: 2px solid rgba( ${props => props.pageData.button_color.rgb.r}, 
                                ${props => props.pageData.button_color.rgb.g},
                                ${props => props.pageData.button_color.rgb.b},
                                ${props => props.pageData.button_color.rgb.a} );
                color: rgba( ${props => props.pageData.button_color.rgb.r}, 
                                ${props => props.pageData.button_color.rgb.g},
                                ${props => props.pageData.button_color.rgb.b},
                                ${props => props.pageData.button_color.rgb.a} );
            }
            .react-add-to-calendar {
              width: 100%;
              ul {
                padding: 0;
                li {
                  list-style: none;
                  padding: 12px;
                  margin-bottom: 8px;
                  border: 2px solid rgba( ${props => props.pageData.button_color.rgb.r}, 
                                ${props => props.pageData.button_color.rgb.g},
                                ${props => props.pageData.button_color.rgb.b},
                                ${props => props.pageData.button_color.rgb.a} );
                }
              }
              &__button {
                width: 100%;
                display: inline-block;
              }
              &__dropdown {

              }
            }
        }
    `

class WelcomePage extends React.Component {

  constructor(props) {
    const data = props.data.linktreeData.nodes[0];
    super(props);
    this.state = {
      pageData: props.data.linktreeData.nodes[0],
      enableRemindMeButton: data.enable_remind_me_button,
      remindMeButtonLabel: data.remind_me_button_label,
      eventCMSTime: data.event_time,
      eventDaysLater: data.event_days_later,
      calendarEvent: {
        title: data.event_title,
        description: data.event_description,
        location: data.event_location,
        startTime: '',
        endTime: ''
      }
    };
  }

  componentDidMount() {
    this.getStartTime();
    this.getEndTime();
  }

  getStartTime = () => {
    const d = new Date();
    const currentYear = d.getFullYear();
    const currentMonthOriginal = parseInt(d.getMonth()) + 1;
    const currentMonthFormatted = currentMonthOriginal < 10 ? `0${currentMonthOriginal}` : currentMonthOriginal;
    let dayOfEvent
    if (this.state.eventDaysLater) {
      dayOfEvent = d.getDate() + parseInt(this.state.eventDaysLater)
    }
    else {
      dayOfEvent = d.getDate() + 1
    }
    const formattedCMSTime = this.state.eventCMSTime ? `${this.state.eventCMSTime.replace(':', '')}+10` : '1000+10';

    const returnedDate = `${currentYear}${currentMonthFormatted}${dayOfEvent}T${formattedCMSTime}`

    

    this.setState(prevState => ({
      calendarEvent: {                   // object that we want to update
          ...prevState.calendarEvent,    // keep all other key-value pairs
          startTime: returnedDate      // update the value of specific key
      }
    }))
  }

  getEndTime = () => {
    const d = new Date();
    const currentYear = d.getFullYear();
    const currentMonthOriginal = parseInt(d.getMonth()) + 1;
    const currentMonthFormatted = currentMonthOriginal < 10 ? `0${currentMonthOriginal}` : currentMonthOriginal;

    let dayOfEvent
    if (this.state.eventDaysLater) {
      dayOfEvent = d.getDate() + parseInt(this.state.eventDaysLater)
    }
    else {
      dayOfEvent = d.getDate() + 1
    }

    let oneHourAhead
    if (this.state.eventCMSTime) {
      const CMSTimeAsNumber = parseInt(this.state.eventCMSTime.replace(':', '')) //Eg 10:00 = 1000
      oneHourAhead = CMSTimeAsNumber + 100 // Eg 1415 (2:15) = 1515 (3:15)
    }
    
    const formattedCMSTime = oneHourAhead ? `${oneHourAhead}+10` : '1100+10';

    const returnedDate = `${currentYear}${currentMonthFormatted}${dayOfEvent}T${formattedCMSTime}`
    console.log('date', returnedDate)
    this.setState(prevState => ({
      calendarEvent: {                   // object that we want to update
          ...prevState.calendarEvent,    // keep all other key-value pairs
          endTime: returnedDate      // update the value of specific key
      }
    }))
  }

  render() {

    const pageData = this.state.pageData;
    // console.log('oage', this.state.props)
    
  
      return (
        <>
          <Helmet title="Welcome | Mimi Casting"/>
          <LinktreeStyles pageData={pageData}>
            <div className="linktree-wrapper">
              <div className="linktree-container">
                  <div className="logo-wrapper">
                    <Link to={'/'}>
                      {
                        pageData.logo && 
                          <div className="logo">
                            <GatsbyImage image={pageData.logo.asset.gatsbyImageData} objectPosition={"center"} loading={"eager"} width={100} objectFit={"cover"}/>
                          </div>
                      }
                      {
                        !pageData.logo && 
                          <div className="logotype">MIMI CASTING</div>
                      }
                    </Link>
                  </div>
                  {
                    pageData.buttons.map( button => {
                      if (button.image) {
                        return (
                          <a href={button.url}>
                            <GatsbyImage image={button.image.asset.gatsbyImageData} objectPosition={"center"} loading={"eager"} objectFit={"cover"}/>
                          </a>
                        )
                      }
                      else {
                        return (
                          <a href={button.url} className="button">{button.title}</a>
                          )
                      }
                      
                    })
                  }
                  {
                    this.state.enableRemindMeButton &&
                    <div className="button">
                      <AddToCalendar event={this.state.calendarEvent} buttonLabel={this.state.remindMeButtonLabel ? this.state.remindMeButtonLabel : 'Remind Me'}/>
                    </div>
                  }
              </div>
            </div>
            {
              pageData.background_image &&
                <div className="background-image">
                  <GatsbyImage image={pageData.background_image.asset.gatsbyImageData} objectPosition={"center"} loading={"eager"} width={388} objectFit={"cover"}/>
                </div>
            }
            
          </LinktreeStyles>
        </>
      )
  }

}

export default WelcomePage







// export default function LinkTree({ data }) {

// const pageData = data.linktreeData.nodes[0];
// // console.log('pagedata: ', pageData)

//     return (
//       <>
        
//       </>
//     )
// }


export const query = graphql`
query {
    linktreeData: allSanityLinktree {
    nodes {
      bg_gradient_one {
        hex
      }
      bg_gradient_two {
        hex
      }
      bg_gradient_three {
        hex
      }
      enable_bg_gradient 
      bg_color {
        rgb {
          r
          g
          b
          a
        }
      }
      background_image {
        asset {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
      logo {
        asset {
          gatsbyImageData(layout: CONSTRAINED, width: 100)
        }
      }
      buttons {
        title
        url
        image {
          asset {
            gatsbyImageData(layout: CONSTRAINED, width: 400)
          }
        }
      }
      button_color {
        rgb {
          r
          g
          b
          a
        }
      }
      enable_remind_me_button
      remind_me_button_label
      event_description
      event_location
      event_time
      event_days_later
      event_title
    }
  }
}
`




// title: 'Sample Event',
// description: 'This is the sample event provided as an example only',
// location: 'Portland, OR',
// startTime: '2016-09-16T20:15:00-04:00',
// endTime: '2016-09-16T21:45:00-04:00'